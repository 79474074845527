import './App.css';
import ListClient from './Components/ListClient/ListClient';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AccountPage from './Components/AccountPage/AccountPage';
import InstallProduct from './Components/Install-Product/InstallProduct';
import Login from "./Components/Login/login";
import ReleaseNotesComponent from './Components/Release-notes/Release-Notes';
import CreateSandbox from './Components/Sandbox-Account/Sandbox-Account';


const clients = [
  { id: 1, name: 'Staging', awsAccountId: '637423650759', region: 'us-east-1' },
  { id: 2, name: 'Production', awsAccountId: '902610975495', region: 'us-east-1' },
  { id: 3, name: 'Brotherhood', awsAccountId: '975050251115', region: 'us-east-1' },
];

function App() {
  return (
    <Router>
      <Routes>
          <Route exact path="/" element={<Login/>}/>
        <Route exact path="/client-list" element={<ListClient clients={clients} />} />
        <Route path="/account/:accountId" element={<AccountPage />} />
        <Route path="install-product" element={<InstallProduct />} />
        <Route path="create-release-notes" element={<InstallProduct />} />
        <Route path="send-release-notes" element={<ReleaseNotesComponent />} />
        <Route path="create-sandbox-account" element={<CreateSandbox />} />
      </Routes>
    </Router>
  );
}

export default App;
