import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom'; 
import './AccountPage.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar/Sidebar';

const AccountPage = () => {
    const location = useLocation();
    const navigate = useNavigate(); 
    const { awsAccountId, name, region } = location.state || {};
    const [loading, setLoading] = useState(true);
    const [pipelines, setPipelines] = useState([]);

    const fetchData = useCallback(async () => {
        const authToken = localStorage.getItem('authToken');
        if (!awsAccountId || !name || !authToken) {
            navigate('/'); 
            return;
        }
        try {
            const response = await fetch('https://api.deployment.avm.technology/listpipelines', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken}` 
                },
                body: JSON.stringify({
                    accountId: awsAccountId,
                    roleName: 'AVMCodePipelineAccess',
                    region: region
                })
            });

            if (response.status === 401) {
                navigate('/'); 
                return;
            }

            const data = await response.json();
            setPipelines(data.pipelines);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [awsAccountId, name, region, navigate]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleDeployClick = async (pipelineName) => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
            navigate('/'); 
            return;
        }

        try {
            const response = await fetch('https://api.deployment.avm.technology/trigger-pipeline', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `${authToken}` 
                },
                body: JSON.stringify({
                    accountId: awsAccountId,
                    roleName: 'AVMCodePipelineAccess',
                    region: region,
                    pipelineName: pipelineName
                })
            });

            if (response.status === 401) {
                navigate('/'); 
                return;
            }
            if (response.ok) {
                return { success: true, pipelineName };
            } else {
                throw new Error(`Failed to deploy ${pipelineName}`);
            }
        } catch (error) {
            return { success: false, pipelineName };
        }
    };

    const handleDeployAllClick = async () => {
        setLoading(true);
        await fetchData();  // Refresh the list of pipelines
        setLoading(false);

        const results = await Promise.all(pipelines.map(pipeline => handleDeployClick(pipeline)));

        const failures = results.filter(result => !result.success);
        if (failures.length === 0) {
            toast.success('Successfully started deployment for all pipelines', { autoClose: 3000 });
        } else {
            failures.forEach(failure => {
                toast.error(`Deploy failed for ${failure.pipelineName}. Please check with DevOps Engineer`, { autoClose: 3000 });
            });
        }
    };

    return (
        <div>
            <Sidebar />
            <div style={{ display: 'flex' }}>
                <ToastContainer />
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <div className="client-table-container" style={{ marginLeft: '250px', padding: '20px', width: 'calc(100% - 220px)' }}>
                        <h2>Pipelines for {name}</h2>
                        <table className="pipeline-table">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>Pipeline Name</th>
                                    <th>Deploy</th>
                                    <th>Latest Execution Status</th>
                                    <th>Most Recent Execution Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pipelines.map((pipeline, index) => (
                                    <tr key={`${pipeline}-${index}`}>
                                        <td>{index + 1}</td> 
                                        <td>{pipeline}</td>
                                        <td>
                                            <button onClick={() => handleDeployClick(pipeline).then(result => {
                                                if (result.success) {
                                                    toast.success(`Deployment started for ${pipeline}`, { autoClose: 3000 });
                                                } else {
                                                    toast.error(`Deploy failed for ${pipeline}. Please check with DevOps Engineer`, { autoClose: 3000 });
                                                }
                                            })}>Deploy</button>
                                        </td>
                                        <td>Last Execution Status</td> 
                                        <td>Recent Execution Status</td>
                                    </tr>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td colSpan="5" style={{ textAlign: 'center' }}>
                                        <button onClick={handleDeployAllClick}>Deploy All</button>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default AccountPage;