import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import './releasenotes.css'; 
import Sidebar from '../Sidebar/Sidebar';

const ReleaseNotesComponent = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedClient, setSelectedClient] = useState('');
  const [releaseNotes, setReleaseNotes] = useState('');

  const navigate = useNavigate();
  const clients = ["AVM", "Brotherhood", "Zaxbys"];

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/');
    }
  }, [navigate]);

  const validateDates = () => {
    if (!startDate || !endDate) {
      toast.error("Start date and end date are required.");
      return false;
    }
    if (new Date(startDate) >= new Date(endDate)) {
      toast.error("Start date must be earlier than end date.");
      return false;
    }
    return true;
  };

  const handleGenerateReleaseNotes = async () => {
    if (!validateDates()) return;
    setReleaseNotes('');

    try {
      const res = await fetch('https://api.deployment.avm.technology/release-notes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${localStorage.getItem('authToken')}`
        },
        body: JSON.stringify({ start_date: startDate, end_date: endDate })
      });

      if (res.status === 401) {
        navigate('/');
        return;
      }

      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const data = await res.json();
      const responseBody = JSON.parse(data.body); // Parse the body JSON string
      const newReleaseNotes = responseBody.release_notes;
      setReleaseNotes(newReleaseNotes);
    } catch (error) {
      console.error("Error generating release notes:", error);
      setReleaseNotes(`Error: ${error.message}`);
    }
  };

  const handleSendReleaseNote = () => {
    console.log("Sending release note...");
  };

  return (
    <div>
      <Sidebar />
      <div className="form-container">
        <h1>Release Notes</h1>
        <div className="form-columns">
          <div className="column">
            <div className="form-group">
              <label htmlFor="start_date">Start Date</label>
              <input 
                type="date" 
                id="start_date" 
                value={startDate} 
                onChange={(e) => setStartDate(e.target.value)} 
              />
            </div>
            <div className="form-group">
              <label htmlFor="end_date">End Date</label>
              <input 
                type="date" 
                id="end_date" 
                value={endDate} 
                onChange={(e) => setEndDate(e.target.value)} 
              />
            </div>
            <div className="form-group">
              <label htmlFor="client">Select Client</label>
              <select 
                id="client" 
                value={selectedClient} 
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                <option value="">--Select Client--</option>
                {clients.map(client => (
                  <option key={client} value={client}>{client}</option>
                ))}
              </select>
            </div>
            <div className="submit-row">
              <button onClick={handleGenerateReleaseNotes}>Generate Release Notes</button>
              <button onClick={handleSendReleaseNote}>Send Release Note</button>
            </div>
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="release_notes">Release Notes</label>
          <textarea 
            id="release_notes" 
            value={releaseNotes} 
            onChange={(e) => setReleaseNotes(e.target.value)}
            rows="10" 
            style={{ width: '98%', padding: '10px',paddingRight: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
          />
        </div>
        <ToastContainer />
      </div>
    </div>
  );
};

export default ReleaseNotesComponent;
