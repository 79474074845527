import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './ClientTable.css'; 
import Sidebar from '../Sidebar/Sidebar';

const ListClient = ({ clients }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/');
    }
  }, [navigate]);

  const handleRowClick = (awsAccountId, name, region) => {
    navigate(`/account/${name}`, { state: { awsAccountId, name, region}});
  };

  

  const authToken = localStorage.getItem('authToken');
  if (!authToken) {
    return null;
  }

  return (
    <div style={{ display: 'flex' }}>
      <Sidebar />
      <div className="client-table-container" style={{ marginLeft: '250px', padding: '20px', width: 'calc(100% - 220px)' }}>
        <h2>Client List</h2>
        
        <table className="client-table">
          <thead>
            <tr>
              <th>Sr No</th>
              <th>Client Name</th>
              <th>AWS Account ID</th>
            </tr>
          </thead>
          <tbody>
            {clients.map((client, index) => (
              <tr key={`${client.id}-${index}`} onClick={() => handleRowClick(client.awsAccountId, client.name, client.region)}>
                <td>{index + 1}</td> 
                <td>{client.name}</td>
                <td>{client.awsAccountId}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListClient;