import React from 'react';
import { Link } from 'react-router-dom';
import AVMLogo from '../../Assets/Logo/AVMLogoWhite.svg';

const sidebarStyle = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '200px',
  padding: '20px',
  backgroundColor: 'rgb(19, 31, 77)',
  height: '100vh',
  position: 'fixed',
  color: 'white'
};

const logoStyle = {
  textAlign: 'left',
  marginBottom: '20px'
};

const linksContainerStyle = {
  flex: '1 1 auto'
};

const tabStyle = {
  display: 'block',
  padding: '10px 15px',
  textDecoration: 'none',
  color: 'white',
  backgroundColor: 'rgb(19, 31, 77)',
  borderRadius: '5px',
  margin: '5px 0',
  transition: 'background-color 0.3s ease',
  fontFamily: 'Roboto'
};

const disabledTabStyle = {
  ...tabStyle,
  opacity: '0.5', 
  pointerEvents: 'none'
};

const logoutStyle = {
  cursor: 'pointer',
  color: 'white',
  backgroundColor: 'rgb(19, 31, 77)',
  borderRadius: '5px',
  padding: '10px 15px',
  marginTop: '10px',
  textAlign: 'left',
  marginBottom: '40px' ,
  transition: 'background-color 0.3s ease',
  fontFamily: 'Roboto'
};

const handleLogout = () => {
  localStorage.setItem('authToken', "");
  window.location.href = '/';
};

const Sidebar = () => {
  return (
    <div style={sidebarStyle}>
      <div style={logoStyle}>
        <img src={AVMLogo} alt="Logo" style={{ maxWidth: '100%' }} />
      </div>
      <div style={linksContainerStyle}>
        <Link to="/" style={tabStyle}>Home</Link>
        <Link to="/client-list" style={tabStyle}>ClientList</Link>
        <Link to="/install-product" style={tabStyle}>Install Product</Link>
        <Link to="/create-release-notes" style={disabledTabStyle}>Create Release Notes</Link>
        <Link to="/send-release-notes" style={tabStyle}>Send Release Notes</Link>
        <Link to="/create-sandbox-account" style={tabStyle}>Create Sandbox Account</Link>
      </div>
     <span onClick={handleLogout} style={logoutStyle}>LogOut</span>
    </div>
  );
};

export default Sidebar;