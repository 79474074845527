
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyBw5o2_TCudraFzPrM2VheVthf2yDcmqcQ",
    authDomain: "addvaluemachine-dev.firebaseapp.com",
    projectId: "addvaluemachine-dev",
    storageBucket: "addvaluemachine-dev.appspot.com",
    messagingSenderId: "171553118899",
    appId: "1:171553118899:web:6ca78518588d3d756d2ec6",
    measurementId: "G-6KD17ZWSG1"
};


const app = initializeApp(firebaseConfig);
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()