import React, { useState, useEffect } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const CreateSandbox = () => {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [responseData, setResponseData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (!authToken) {
          navigate('/');
        }
      }, [navigate]);

    const handleSubmit = async (e) => {
      e.preventDefault();
      const authToken = localStorage.getItem('authToken');
      if (!username) {
        setError('Username cannot be empty');
        return;
      }
  
      setError('');
      try {
        const response = await fetch('https://api.deployment.avm.technology/create-sandbox', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `${authToken}`
            },
            body: JSON.stringify({ username }),
          });

        
          if (response.status === 401) {
            navigate('/');
            return;
          }
        else if (response.ok) {
          const jsonResponse = await response.json();
          const data = JSON.parse(jsonResponse.body);
          setResponseData({
            message: data.message,
            username: data.username,
            password: data.password,
            expirationTime: data.account_expiration_time,
            loginLink: data.loginLink,
          });
          setUsername('');
  
          toast.success('User created successfully!',{ autoClose: 3000 });
        } else {
          toast.error('Failed to create user', { autoClose: 3000 });
        }
      } catch (error) {
        toast.error('An error occurred. Please connect with Tech team',{ autoClose: 3000 });
      }
    };
  
    useEffect(() => {
      if (responseData) {
        const timer = setTimeout(() => {
          setResponseData(null);
        }, 300000);
  
        return () => clearTimeout(timer);
      }
    }, [responseData]);
  
    return (
      <div>
        <Sidebar />
        <div className="form-container">
          <h1>Create Sandbox User</h1>
          <div className="form-columns">
            <div className="column">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="username">Username</label>
                  <input
                    type="text"
                    id="username"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className={error ? 'error' : ''}
                  />
                  {error && <div className="error-message">{error}</div>}
                </div>
                <div className="submit-row">
                  <button type="submit">Create User</button>
                </div>
              </form>
            </div>
          </div>
  
          {responseData && (
            <div className="response-message">
              <h2>{responseData.message}</h2>
              <p><strong>Username:</strong> {responseData.username}</p>
              <p><strong>Password:</strong> {responseData.password}</p>
              <p><strong>Account Expiration Time:</strong> {responseData.expirationTime}</p>
              <p><strong>Login Link:</strong> <a href={responseData.loginLink} target="_blank" rel="noopener noreferrer">{responseData.loginLink}</a></p>
            </div>
          )}
        </div>
      </div>
    );
  };
  
export default CreateSandbox;