import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 
import './InstallProduct.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Sidebar from '../Sidebar/Sidebar';

const InstallProduct = () => {
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    client_name: '',
    container_registry_token: '',
    root_domain_name: '',
    firebase_api_key: '',
    firebase_auth_domain: '',
    firebase_project_id: '',
    firebase_storage_bucket: '',
    firebase_messaging_sender_id: '',
    firebase_app_id: '',
    firebase_measurement_id: '',
    firebase_private_key: '',
    firebase_client_email: '',
    workspace_name: '',
    AWS_ACCESS_KEY_ID: '',
    AWS_SECRET_ACCESS_KEY: '',
    AWS_REGION: ''
  });

  const supportedRegions = [
    'us-east-1', 'us-west-2', 'ap-south-1', 'ap-southeast-1', 
    'ap-southeast-2', 'ap-northeast-1', 'ca-central-1', 
    'eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-west-3', 
    'sa-east-1'
  ];

  const awsRegions = [
    'us-east-1', 'us-east-2', 'us-west-1', 'us-west-2', 
    'af-south-1', 'ap-east-1', 'ap-south-1', 'ap-south-2', 
    'ap-northeast-1', 'ap-northeast-2', 'ap-northeast-3', 
    'ap-southeast-1', 'ap-southeast-2', 'ap-southeast-3', 
    'ap-southeast-4', 'ca-central-1', 'ca-west-1', 
    'eu-central-1', 'eu-west-1', 'eu-west-2', 'eu-south-1', 
    'eu-south-2', 'eu-west-3', 'eu-north-1', 'me-south-1', 
    'sa-east-1', 'il-central-1', 'me-central-1'
  ];

  const [errors, setErrors] = useState({});
  const [regionWarning, setRegionWarning] = useState('');
  const [step, setStep] = useState(1);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'AWS_REGION') {
      if (!supportedRegions.includes(value)) {
        setRegionWarning('Bedrock is not available in this region, you need to use the Sagemaker models and/or other models.');
      } else {
        setRegionWarning('');
      }
    }
    setFormData({ ...formData, [name]: value });
  };

  const validate = () => {
    const newErrors = {};
    Object.keys(formData).forEach((field) => {
      if (step === 1 && (field === 'client_name' || field === 'container_registry_token') && !formData[field]) {
        newErrors[field] = 'This field is required';
      }
      if (step === 2 && (field === 'AWS_ACCESS_KEY_ID' || field === 'AWS_SECRET_ACCESS_KEY' || field === 'AWS_REGION') && !formData[field]) {
        newErrors[field] = 'This field is required';
      }
      if (step === 3 && (Object.keys(formData).slice(3, 11).includes(field) && !formData[field])) {
        newErrors[field] = 'This field is required';
      }
      if (step === 4 && (field === 'root_domain_name' || field === 'workspace_name') && !formData[field]) {
        newErrors[field] = 'This field is required';
      }
    });
    return newErrors;
  };

  const handleNext = () => {
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    setErrors({});
    if (step < 4) {
      setStep(step + 1);
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const authToken = localStorage.getItem('authToken');
      const response = await fetch('https://api.deployment.avm.technology/install-product', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `${authToken}`
        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 401) {
        navigate('/');
        return;
      } else if (!response.ok) {
        throw new Error('Installation Failed');
      }
  
      toast.success('Installation Started Successfully!');
      setFormData({
        client_name: '',
        container_registry_token: '',
        root_domain_name: '',
        firebase_api_key: '',
        firebase_auth_domain: '',
        firebase_project_id: '',
        firebase_storage_bucket: '',
        firebase_messaging_sender_id: '',
        firebase_app_id: '',
        firebase_measurement_id: '',
        firebase_private_key: '',
        firebase_client_email: '',
        workspace_name: '',
        AWS_ACCESS_KEY_ID: '',
        AWS_SECRET_ACCESS_KEY: '',
        AWS_REGION: ''
      });
      setStep(1);
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast.error('Something went wrong. Please contact DevOps Engineer');
    }
  };

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (!authToken) {
      navigate('/');
    }
  }, [navigate]);

  return (
    <div>
      <Sidebar />
      <div className="form-container">
        <h1>AVM Product Installation</h1>
        <form className="pipeline-table" onSubmit={handleSubmit}>
          {/* Step 1 */}
          {step === 1 && (
            <div className="form-step">
              <h2>Step 1: Client Details & GitHub Token</h2>
              <div className="form-group">
                <label htmlFor="client_name">Client Name</label>
                <input
                  type="text"
                  id="client_name"
                  name="client_name"
                  value={formData.client_name}
                  onChange={handleChange}
                  className={errors.client_name ? 'error' : ''}
                />
                {errors.client_name && <div className="error-message">{errors.client_name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="container_registry_token">Container Registry Token</label>
                <input
                  type="text"
                  id="container_registry_token"
                  name="container_registry_token"
                  value={formData.container_registry_token}
                  onChange={handleChange}
                  className={errors.container_registry_token ? 'error' : ''}
                />
                {errors.container_registry_token && <div className="error-message">{errors.container_registry_token}</div>}
              </div>
            </div>
          )}

          {/* Step 2 */}
          {step === 2 && (
            <div className="form-step">
              <h2>Step 2: AWS Credentials and Region</h2>
              <div className="form-group">
                <label htmlFor="AWS_ACCESS_KEY_ID">AWS Access Key ID</label>
                <input
                  type="text"
                  id="AWS_ACCESS_KEY_ID"
                  name="AWS_ACCESS_KEY_ID"
                  value={formData.AWS_ACCESS_KEY_ID}
                  onChange={handleChange}
                  className={errors.AWS_ACCESS_KEY_ID ? 'error' : ''}
                />
                {errors.AWS_ACCESS_KEY_ID && <div className="error-message">{errors.AWS_ACCESS_KEY_ID}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="AWS_SECRET_ACCESS_KEY">AWS Secret Access Key</label>
                <input
                  type="text"
                  id="AWS_SECRET_ACCESS_KEY"
                  name="AWS_SECRET_ACCESS_KEY"
                  value={formData.AWS_SECRET_ACCESS_KEY}
                  onChange={handleChange}
                  className={errors.AWS_SECRET_ACCESS_KEY ? 'error' : ''}
                />
                {errors.AWS_SECRET_ACCESS_KEY && <div className="error-message">{errors.AWS_SECRET_ACCESS_KEY}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="AWS_REGION">AWS Region</label>
                <select
                  id="AWS_REGION"
                  name="AWS_REGION"
                  value={formData.AWS_REGION}
                  onChange={handleChange}
                  className={errors.AWS_REGION ? 'error' : ''}
                >
                  <option value="">Select AWS Region</option>
                  {awsRegions.map((region) => (
                    <option key={region} value={region}>{region}</option>
                  ))}
                </select>
                {errors.AWS_REGION && <div className="error-message">{errors.AWS_REGION}</div>}
                {regionWarning && <div className="warning-message">{regionWarning}</div>}
              </div>
            </div>
          )}

          {step === 3 && (
            <div className="form-step">
              <div className="form-group">
              <h2>Step 3: Firebase Details</h2>
                <label htmlFor="firebase_api_key">Firebase API Key</label>
                <input
                  type="text"
                  id="firebase_api_key"
                  name="firebase_api_key"
                  value={formData.firebase_api_key}
                  onChange={handleChange}
                  className={errors.firebase_api_key ? 'error' : ''}
                />
                {errors.firebase_api_key && <div className="error-message">{errors.firebase_api_key}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_auth_domain">Firebase Auth Domain</label>
                <input
                  type="text"
                  id="firebase_auth_domain"
                  name="firebase_auth_domain"
                  value={formData.firebase_auth_domain}
                  onChange={handleChange}
                  className={errors.firebase_auth_domain ? 'error' : ''}
                />
                {errors.firebase_auth_domain && <div className="error-message">{errors.firebase_auth_domain}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_project_id">Firebase Project ID</label>
                <input
                  type="text"
                  id="firebase_project_id"
                  name="firebase_project_id"
                  value={formData.firebase_project_id}
                  onChange={handleChange}
                  className={errors.firebase_project_id ? 'error' : ''}
                />
                {errors.firebase_project_id && <div className="error-message">{errors.firebase_project_id}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_storage_bucket">Firebase Storage Bucket</label>
                <input
                  type="text"
                  id="firebase_storage_bucket"
                  name="firebase_storage_bucket"
                  value={formData.firebase_storage_bucket}
                  onChange={handleChange}
                  className={errors.firebase_storage_bucket ? 'error' : ''}
                />
                {errors.firebase_storage_bucket && <div className="error-message">{errors.firebase_storage_bucket}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_messaging_sender_id">Firebase Messaging Sender ID</label>
                <input
                  type="text"
                  id="firebase_messaging_sender_id"
                  name="firebase_messaging_sender_id"
                  value={formData.firebase_messaging_sender_id}
                  onChange={handleChange}
                  className={errors.firebase_messaging_sender_id ? 'error' : ''}
                />
                {errors.firebase_messaging_sender_id && <div className="error-message">{errors.firebase_messaging_sender_id}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_app_id">Firebase App ID</label>
                <input
                  type="text"
                  id="firebase_app_id"
                  name="firebase_app_id"
                  value={formData.firebase_app_id}
                  onChange={handleChange}
                  className={errors.firebase_app_id ? 'error' : ''}
                />
                {errors.firebase_app_id && <div className="error-message">{errors.firebase_app_id}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_measurement_id">Firebase Measurement ID</label>
                <input
                  type="text"
                  id="firebase_measurement_id"
                  name="firebase_measurement_id"
                  value={formData.firebase_measurement_id}
                  onChange={handleChange}
                  className={errors.firebase_measurement_id ? 'error' : ''}
                />
                {errors.firebase_measurement_id && <div className="error-message">{errors.firebase_measurement_id}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_private_key">Firebase Private Key</label>
                <input
                  type="text"
                  id="firebase_private_key"
                  name="firebase_private_key"
                  value={formData.firebase_private_key}
                  onChange={handleChange}
                  className={errors.firebase_private_key ? 'error' : ''}
                />
                {errors.firebase_private_key && <div className="error-message">{errors.firebase_private_key}</div>}
              </div>

              <div className="form-group">
                <label htmlFor="firebase_client_email">Firebase Client Email</label>
                <input
                  type="text"
                  id="firebase_client_email"
                  name="firebase_client_email"
                  value={formData.firebase_client_email}
                  onChange={handleChange}
                  className={errors.firebase_client_email ? 'error' : ''}
                />
                {errors.firebase_client_email && <div className="error-message">{errors.firebase_client_email}</div>}
              </div>
            </div>
          )}

          {/* Step 4 */}
          {step === 4 && (
            <div className="form-step">
              <h2>Step 4: Domain Name Details</h2>
              <div className="form-group">
                <label htmlFor="root_domain_name">Root Domain Name</label>
                <input
                  type="text"
                  id="root_domain_name"
                  name="root_domain_name"
                  value={formData.root_domain_name}
                  onChange={handleChange}
                  className={errors.root_domain_name ? 'error' : ''}
                />
                {errors.root_domain_name && <div className="error-message">{errors.root_domain_name}</div>}
              </div>
              <div className="form-group">
                <label htmlFor="workspace_name">Workspace Name</label>
                <input
                  type="text"
                  id="workspace_name"
                  name="workspace_name"
                  value={formData.workspace_name}
                  onChange={handleChange}
                  className={errors.workspace_name ? 'error' : ''}
                />
                {errors.workspace_name && <div className="error-message">{errors.workspace_name}</div>}
              </div>
            </div>
          )}

          <div className="form-navigation">
            {step > 1 && (
              <button type="button" onClick={handleBack}>Back</button>
            )}
            {step < 4 && (
              <button type="button" onClick={handleNext}>Next</button>
            )}
            {step === 4 && (
              <button type="submit">Submit</button>
            )}
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default InstallProduct;