import { auth } from '../config/firebase'
import { signInWithPopup, SAMLAuthProvider } from 'firebase/auth'
import { useNavigate } from 'react-router-dom';
import logo from '../../Assets/Logo/AVM-logo.svg'
import './login.css'

const provider = new SAMLAuthProvider('saml.avm-dev');

function Login() {
    const navigate = useNavigate(); 
    const authenticateWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, provider);
            const token = await result.user.getIdToken();
            localStorage.setItem('authToken', token);
            navigate('/client-list');
        } catch (error) {
            console.error("Error authenticating with Google:", error);
        }
    };


    return (
        <div className="container">
            <div className="card">
                <div className="card-content">
                    
                    <div className="logo-container">
                        <img src={logo} alt="Logo" className="logo" />
                    </div>

                    <h1>Click to Login in AVM Deployment Portal</h1>
                    <div className="button-container">
                        <button onClick={authenticateWithGoogle}>
                            Authenticate
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login;
